<template>
  <div class="bg-index">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/index' }">会议壳</el-breadcrumb-item>
      <el-breadcrumb-item>公用背景</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <div class="add-area">
        <el-button type="primary" @click="addPhotosVisible = true">添加背景图</el-button>
      </div>
      <div class="bg-list" v-if="bgList.length > 0">
        <div class="bg-item" v-for="item in bgList" :key="item.id">
          <el-image :src="item.url" lazy></el-image>
          <div class="photo-info">
            <el-button type="danger" size="mini" @click="delPhotoItem(item.id)">删除</el-button>
          </div>
        </div>
      </div>
      <el-empty :image-size="200" v-else></el-empty>
    </div>
    <!-- 上传图片弹窗 -->
    <el-dialog title="上传图片" :visible.sync="addPhotosVisible" width="60%" :before-close="addPhotosClose">
      <div class="dialog-form">
        <el-form :model="uploadPhotosForm" ref="addPhotosRef" class="dialog-form-content" label-position="top">
          <el-form-item label="选取图片（多张）" prop="uploadList">
            <upload :urlArr="uploadPhotosForm.uploadList" :filed="'uploadList'" :max-num="10" @checked-files="checkedFiles"></upload>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addPhotosVisible = false">取 消</el-button>
        <el-button type="primary" @click="addPhotosSubmit()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Upload from '../../../common/Upload'
export default {
  name: 'Index',
  components: {
    Upload
  },
  data () {
    return {
      bgList: [],
      // 待上传图片列表
      uploadPhotosForm: {
        uploadList: []
      },
      // 上传图片弹窗是否可见
      addPhotosVisible: false
    }
  },
  created () {
    this.getBgList()
  },
  methods: {
    // 获取背景列表
    async getBgList () {
      const { data: res } = await this.$http.get('/shell-bg-list')
      if (res.status === 200) {
        this.bgList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    // 删除
    async delItem (id) {
      const { data: res } = await this.$http.delete('/shell-bg/' + id)
      if (res.status === 200) {
        this.$message.success(res.msg)
        await this.getBgList()
      } else {
        this.$message.error(res.msg)
      }
    },
    // 上传组件返回值
    checkedFiles (data) {
      this.uploadPhotosForm.uploadList = data.url
    },
    // 确认上传图片
    async addPhotosSubmit () {
      const { data: res } = await this.$http.post('/shell-bg/0', { urlArr: this.uploadPhotosForm.uploadList })
      if (res.status === 200) {
        this.$message.success(res.msg)
        // 清空待上传表单
        this.uploadPhotosForm.uploadList = []
        // 重新获取图片列表
        await this.getBgList()
        this.addPhotosVisible = false
      } else {
        this.$message.error(res.msg)
      }
    },
    // 关闭上传图片弹窗
    addPhotosClose () {
      this.uploadPhotosForm.uploadList = []
      this.addPhotosVisible = false
    },
    // 删除某一张图片
    async delPhotoItem (id) {
      const { data: res } = await this.$http.delete('/shell-bg/' + id)
      if (res.status === 200) {
        await this.getBgList()
        this.$message.success(res.msg)
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>

<style scoped>
.add-area{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.bg-list{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.bg-list .bg-item{
  width: 120px;
  margin: 20px;
}
.bg-list .bg-item .el-image{}
.bg-list .bg-item .photo-info{}
</style>
